import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import remove from 'lodash/remove'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'

import memoize from 'memoize-one'

import EmployeesTable from './EmployeesTable.js'
import { getEmployeeData, getMembership, loading } from './redux/employeesActions'
import { getTipoContrato } from '../../utils.js'

const columns = [
  { name: 'id', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'email', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'telefono', options: { viewColumns: false, display: 'excluded', filter: false } },
  { name: 'fechaNacimiento', options: { viewColumns: false, display: 'excluded', filter: false } }
]
const defaultColumns = ['rut', 'nombre', 'apellido', 'predio', 'tipoContrato', 'fechaInicio', 'declarado']

const _employeesList = memoize((employeesMemberships) => map(employeesMemberships, (membership) => remove(membership.split('/'), (v, idx) => (idx === 1 || idx === 2)).join('/')))

const _data = memoize((employeesList, employeeRecord, employeeContracts, farmRecords, contractTypeRecords) => reduce(employeesList, (result, employeeRecordId) => {
  let employee = employeeRecord[employeeRecordId]
  let farm = null
  let employeeContract = null
  if (!isEmpty(employeeContracts)) {
    farm = employeeContracts[employeeRecordId] ? employeeContracts[employeeRecordId].farm : null
    employeeContract = employeeContracts[employeeRecordId]
  }

  if (farm) farm = farmRecords[`farm/${employeeContracts[employeeRecordId].farm}`] ? farmRecords[`farm/${employeeContracts[employeeRecordId].farm}`].displayName : 'n/d'

  const employeeContractType = employeeContract ? `contract/${employeeContract.type}` : 'contract/type/'
  const contractTypeRecord = contractTypeRecords[employeeContractType]
  let relevant = {
    rut: employeeRecordId.split('/')[1],
    nombre: employee ? employee.name : 'n/d',
    apellido: employee ? employee.lastName : 'n/d',
    predio: farm || 'n/d',
    tipoContrato: getTipoContrato(contractTypeRecord),
    fechaInicio: employeeContract ? employeeContract.beginDate : 'No definido',
    declarado: (employeeContract && employeeContract.undeclared) ? 'No' : 'Si',
    email: employee.email,
    fechaNacimiento: employee.birthDate,
    telefono: employee.phone,
    id: `__employeeRecordId__:${employeeRecordId}`
  }
  for (var key in relevant) {
    if (key !== 'id') {
      if (!includes(map(columns, (c) => c.name), key)) {
        columns.push({ name: key, label: lowerCase(key), options: { display: 'true', viewColumns: true } })
      }
    }
  }
  result.push(relevant)
  return result
}, []))

// COLUMNS WITH PROGRAMATIC FILTERS BASED ON QUERY PARAMS
const _columns = memoize((columns, location) => {
  const orderedColumns = orderBy(columns, (o) => defaultColumns.indexOf(o.name))

  // we filter based on query
  const query = getQuery(location)
  return reduce(orderedColumns, (result, column, idx) => {
    const value = query.get(column.name)
    column.options = { ...column.options, filterList: value ? [value] : [] }
    result.push(column)
    return result
  }, [])
})

const getQuery = (location) => {
  return new URLSearchParams(location.search)
}

const mapStateToProps = (state, ownProps) => {
  const companyId = state.auth.clientData.activeCompany
  const farmRecords = state.farms.farmRecord
  const employeesMemberships = state.employees.companyEmployeesList[`company/${companyId}/list/employees`]
  const employeeContracts = state.employees.employeeContract
  const employeesList = _employeesList(employeesMemberships)
  const contractTypeRecords = state.employees.contractTypeRecord

  return {
    data: _data(employeesList, state.employees.employeeRecord, employeeContracts, farmRecords, contractTypeRecords),
    columns: _columns(columns, ownProps.location),
    options: {
      responsive: 'scrollMaxHeight',
      expandableRows: false,
      selectableRows: 'multiple',
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100]
    },
    loading: state.employees.loading || false
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getEmployeeData,
  dispatchLoading: loading,
  getMembership
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesTable)
