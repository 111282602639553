import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import HarvestDayStatsCard from './HarvestDayStatsCard'
import HarvestFarmStatsCard from './HarvestFarmStatsCard'
import HarvestCompanyStatsCard from './HarvestCompanyStatsCard'
import HarvestCompanyDayStatsCard from './HarvestCompanyDayStatsCard'
import UserAccessComponent from '../Components/UserAccessComponent'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing.unit / 2
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
})

class Harvest extends React.Component {
  componentDidMount() {
    this.props.startHarvestData()
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={3}>
            <Button className={classes.button} variant='contained' component={Link} to={{ pathname: '/app/harvest/input', state: { sites: this.props.sites, formats: this.props.formats, qualities: this.props.qualities } }}>
              Captura Cosecha
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button className={classes.button} variant='contained' component={Link} to={{ pathname: '/app/harvest/table', state: { sites: this.props.sites, formats: this.props.formats, qualities: this.props.qualities, farms: this.props.farms } }}>
              Registros diarios
            </Button>
          </Grid>
          <Grid item xs={3}>
            {(!this.props.access || this.props.access > 3) ? <></>
              : <Button className={classes.button} variant='contained' component={Link} to={{ pathname: '/app/harvest/validate-day', state: { sites: this.props.sites, formats: this.props.formats, qualities: this.props.qualities, farms: this.props.farms } }}>
                Cierre diario
              </Button>
            }
          </Grid>
          <Grid item xs={3}>
            {(!this.props.access || this.props.access > 3) ? <></>
              : <Button className={classes.button} variant='contained' component={Link} to={{ pathname: '/app/harvest/summary', state: { sites: this.props.sites, formats: this.props.formats, qualities: this.props.qualities, farms: this.props.farms } }}>
                Resumen por cosechero
              </Button>
            }
          </Grid>
        </Grid>

        <br />
        <br />

        <Grid container spacing={24}>
          <Grid item sm={6} xs={12}>
            <HarvestCompanyDayStatsCard
              sites={this.props.sites}
              formats={this.props.formats}
              qualities={this.props.qualities}
              farms={this.props.farms}
              harvestDayStats={this.props.harvestStats}
              computeFarmDayStats={this.props.computeFarmDayStats}
              statsCompute={this.props.statsCompute}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <HarvestCompanyStatsCard
              sites={this.props.sites}
              formats={this.props.formats}
              qualities={this.props.qualities}
              farms={this.props.farms}
              harvestSeasonStats={this.props.harvestSeasonStats}
              computeCompanyStats={this.props.computeCompanyStats}
              statsCompute={this.props.statsCompute}
            />
          </Grid>
          <Grid item xs={12}>
            <HarvestDayStatsCard
              harvestStatsSubscribe={this.props.harvestStatsSubscribe}
              harvestStatsUnsubscribe={this.props.harvestStatsUnsubscribe}
              sites={this.props.sites}
              formats={this.props.formats}
              qualities={this.props.qualities}
              farms={this.props.farms}
              harvestStats={this.props.harvestStats}
            />
          </Grid>
          <Grid item xs={12}>
            <HarvestFarmStatsCard
              sites={this.props.sites}
              formats={this.props.formats}
              qualities={this.props.qualities}
              farms={this.props.farms}
              harvestSeasonStats={this.props.harvestSeasonStats}
            />
          </Grid>
          <UserAccessComponent userAccess={this.props.access} access={2}>
            <code>
              {JSON.stringify(this.props.sites)}
              {JSON.stringify(this.props.sites)}
              {JSON.stringify(this.props.formats)}
              {JSON.stringify(this.props.qualities)}
              {JSON.stringify(this.props.farms)}
            </code>
          </UserAccessComponent>
        </Grid>
      </div>
    )
  }
}

Harvest.propTypes = {
  classes: PropTypes.object.isRequired,
  startHarvestData: PropTypes.func.isRequired,
  harvestStatsSubscribe: PropTypes.func.isRequired,
  harvestStatsUnsubscribe: PropTypes.func.isRequired,
  computeFarmDayStats: PropTypes.func.isRequired,
  computeCompanyStats: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
  qualities: PropTypes.array.isRequired,
  farms: PropTypes.array.isRequired,
  access: PropTypes.number,
  harvestStats: PropTypes.object.isRequired,
  harvestSeasonStats: PropTypes.object.isRequired,
  statsCompute: PropTypes.bool
}

export default withStyles(styles)(Harvest)
